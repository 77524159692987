.selectedbericht{
    z-index: 900;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    background: rgba(0,0,0,0.1);
}
.selectedbericht__left{
    width: 55vw;
    height: 100vh;
    @media screen and (max-width: 75rem) {
        width: 35vw;
    }
    @media screen and (max-width: 36.5rem) {
        width: 0;
    }
}
.selectedbericht__right{
    height: 100vh;
    width: 45vw;
    background: white;
    box-shadow: 0 0 2rem rgba(0,0,0,0.2);
    padding: 5rem;
    @media screen and (max-width: 75rem) {
        width: 65vw;
    }
    @media screen and (max-width: 36.5rem) {
        width: 100vw;
    }
}

.selectedbericht__right__header{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eceff2;
}
.selectedbericht__right__header__nav{
    display: flex;
    gap: 1.5rem;
    justify-content: space-between;
    align-items: center;
    > div{
        display: flex;
        gap: 1rem;
        align-items: center;
    }
}
.selectedbericht__right__header__nav__close{
    color: #424c55;
    font-size: 2.2rem;
    cursor: pointer;
}
.selectedbericht__right__header__nav__btn{
    background: none;
    border: none;
    cursor: pointer;
    &--icon{
        color: #0057ff;
        font-size: 2.8rem;
    }
}
.selectedbericht__right__header__title{
    font-size: 3rem;
    font-weight: 500;
    color: #424c55;
}

.selectedbericht__right__content{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 3rem;
}
.selectedbericht__right__content__nodetails, .selectedbericht__right__content__details{
    font-size: 1.6rem;
    color: #69717e;
}
.selectedbericht__right__content__text{
  font-size: 1.8rem;
  color: #69717e;
}
.selectedbericht__right__content__link{
    color: #0057ff;
    text-decoration: none;
    &:hover{
        text-decoration: underline;
    }
}