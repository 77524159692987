.totaalmeerwerk{
    padding: 3rem;
    border-top: #eceff2 1px solid;
}

.totaalmeerwerk__h3{
    font-size: 2rem;
    font-weight: 400;
    color: #49535d;
    padding-bottom: 2.5rem;
}

.totaalmeerwerk__wrapper{
    height: 5rem;
    display: flex;
    gap: 1rem;
    @media screen and (max-width: 31.25rem) {
        flex-direction: column;
        height: fit-content;
    }
}
.totaalmeerwerk__wrapper__bar{
    width: 100%;
    background: #f2f6ff;
    border: 1px solid #0057ff;
    color: #0057ff;
    font-size: 1.6rem;
    display: flex;
    gap: 0.4rem;
    align-items: center;
    padding-left: 1.5rem;
    height: 5rem;
    &--bold{
        font-weight: 500;
    }
}

.totaalmeerwerk__wrapper__button{
    font-size: 1.6rem;
    min-width: fit-content;
}