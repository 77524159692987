.projectVerloop{
    padding: 3rem;
    display:  flex;
    flex-direction: column;
    gap: 2.5rem;
    
    .projectVerloop__header{
        display: flex;
        gap: 1rem;
        h3{
            font-size: 2rem;
            font-weight: 400;
            color: #49535d;
        }
        p{
            color: #24d11b;
            font-weight: 300;
            b{
                font-weight: 500;
            }
        }
    }
    .progressBar{
        display: flex;
        width: 100%;
        border-radius: 3px;
        justify-content: space-evenly;

        @media screen and (max-width: 100rem) {
            flex-direction: column;
        }
    }
}
        .progressBar__a{
            font-size: 1.6rem;
            height: 6rem;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #d9dfe6;
            border-left: none;
            color: #7f8a99;
            text-decoration: none;
            position: relative;
            
            &:nth-of-type(1){
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                border: 1px solid #d9dfe6;
                border-left: none!important;
            }
            &:nth-last-of-type(1){
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            @media screen and (max-width: 100rem) {
                border: 1px solid #d9dfe6;
                border-top: none;

                &:nth-of-type(1){
                    border-top-left-radius: 3px;
                    border-top-right-radius: 3px;
                    border-bottom-left-radius: 0;
                    border: 1px solid #d9dfe6;
                }
                &:nth-last-of-type(1){
                    border-bottom-right-radius: 3px;
                    border-bottom-left-radius: 3px;
                    border-top-right-radius: 0;
                }
            }

            &.is-finished{
                background: #24d11b;
                border: none;
                color: white;
                border-left: 1px solid hsl(117, 77%, 66%);
                &::before {
                    position: absolute;
                    display: block;
                    top: 100%;
                    background: #fff;
                    width: 10px;
                    height: 10px;
                    transform: translateY(-50%) rotate(45deg);
                    content: "";
                    z-index: 2;
                    @media screen and (max-width: 100rem) {
                        right: 0;
                        transform: translate(50%, -50%) rotate(45deg);
                    }
                }
                &::after {
                    color: #24d11b;
                    position: absolute;
                    display: block;
                    top: 100%;
                    background: #fff;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    content: "100%";
                    font-weight: 500;
                    padding: 7px 10px;
                    z-index: 1;
                    box-shadow: 0px 3px 3px 0px rgba(0, 4, 21, 0.1);
                    @media screen and (max-width: 100rem) {
                        right: 0;
                        transform: translateX(50%);
                    }
                }
                &--nopercentage{
                    &::after{
                        display: none;
                    }
                    &::before{
                        display: none;
                    }
                }
                &--overtime{
                    background: #ff8400;
                    border-left: none;

                    &::after{
                        color: #ff8400;
                        content: attr(data-percentage);
                    }
                }
            }
            &.is-inprogress{
                background: #eefceb;
                border: none;
                color: #24d11b;
                border-left: 1px solid hsl(109, 74%, 75%);

                
                &::before {
                    position: absolute;
                    display: block;
                    top: 100%;
                    background: #fff;
                    width: 10px;
                    height: 10px;
                    transform: translateY( -50%) rotate(45deg);
                    content: "";
                    z-index: 2;
                    @media screen and (max-width: 100rem) {
                        right: 0;
                        transform: translate(50%, -50%) rotate(45deg);
                    }
                }
                &::after {
                    position: absolute;
                    display: block;
                    top: 100%;
                    background: #fff;
                    border-bottom-left-radius: 5px;
                    font-weight: 500;
                    border-bottom-right-radius: 5px;
                    content: "Lopend";
                    padding: 7px 10px;
                    z-index: 1;
                    color: #24d11b;
                    box-shadow: 0px 3px 3px 0px rgba(0, 4, 21, 0.1);
                    @media screen and (max-width: 100rem) {
                        right: 0;
                        transform: translateX(50%);
                    }
                }
                &--overtime{
                    &::after{
                        content:"Lopend " attr(data-percentage); 
                    }
                    
                }
                .is-finished + & {
                    border-left: none;
                }
                .is-finished--overtime + & {
                    border-left: none;
                }
            }
        }
        
        

.projectVerloop{
    &--datum{
        display: flex;
        justify-content: space-between;

        p{
            font-size: 1.2rem;
            color: #7f8a99;
        }
    }

}
