.projecten{
    display: flex;
    flex-direction: column;
    gap: 5rem;
    padding-top: 4.3rem;

    .projecten__header{
        display: flex;
        flex-direction: column;
        gap: 3rem;
        width: 100%;
        .titleLinksWrapper{
            width: 100%;
            display: flex;
            gap: 1rem;
            a{
                color: #7f8a99;
                transition: 0.4s;
                &:hover{
                    color: hsl(220, 100%, 60%);
                }
            }
            a:nth-of-type(1){
                padding-right: 2rem;
                border-right: #dde1e6 1px solid ;
            }
            @media screen and (max-width: 37.5rem) {
                flex-direction: column;
            }
            &:empty{
                display: none;
            }

        }
        .titleLinksWrapper__button{

        }
    }
    .projecten__header__backlink{
        color: #7f8a99;
        text-decoration: none;
        font-size: 1.6rem;
        transition: 0.1s;
        &:hover{
            color: #0057ff;
        }
    }

    .status{
        background: white;
        width: 100%;
        box-shadow: 0px 5px 5px 0px rgba(0,4,21,0.03);
        border-radius: 3px;

        .status__header{
            border-bottom: #eceff2 1px solid;
            display: flex;
            justify-content: space-between;

            h3{
                padding: 3rem;
                font-size: 2rem;
                font-weight: 400;
                color: #49535d;
            }
            div{
                display: flex;
                align-items: center;
                padding: 1.5rem;
                gap: 3rem;
                .status__header__link{
                    font-size: 1.6rem;
                    color: #0057ff;
                    text-decoration: underline;
                    cursor: pointer;
                }
                .status__header__button{
                    height: 100%;
                    padding: 0 3rem;
                    border-radius: 3px;
                    border: none;
                    color: white;
                    font-size: 1.6rem;
                    cursor: pointer;
                    font-weight: 400;
                }
            }
        }
    }

    
}
