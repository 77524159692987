.btn{
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 13px 30px;
    transition: .3s ease-in;
    transform: translateY(0);
    border-radius: 3px;
    box-shadow: 0px 2px 10px 0px rgba(0,51,96,0.15);
    font-size: calc(15px + (18 - 15) * ((100vw - 400px) / (1920 - 300)));
    font-family: "Roboto",sans-serif;
    color: var(--primary-btn-bg-color);
    background: #0057ff;
    color: white;
    font-weight: 400;
    text-decoration: none;
    &:hover{
        transform: translateY(-0.3rem);
    }
    &--projectdetails{
        color: white;
        background: #0057ff;
        font-size: 1.6rem;
        font-weight: 400;
        border-radius: 3px;
        width: 100%;
        height: 100%;
        min-height: 4rem;
        padding: 0;
        font-family: 'Roboto', sans-serif;
    }
    &--retrysignature{
        background: none;
        color: #0057ff;
        text-decoration: underline;
        box-shadow: none;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 1.6rem;
        position: absolute;
        top: 2rem;
        right: 2rem;
        padding: 0;
    }
    &--bluewhite{
        height: 100%;
        min-height: 5rem;
        font-size: 1.6rem;
        border: none;
        background: white;
        display: flex;
        gap: 1rem;
        padding: 0 2rem;
        align-items: center;
        border-radius: 3px;
        cursor: pointer;
        color: #0057ff;
        box-shadow: 0px 5px 5px 0px rgba(0, 4, 21, 0.03);
        border-bottom: 2px solid transparent;
        color: #0057ff;
        text-transform: capitalize;
        > div{
            fill: #0057ff;
            height: 2rem;
            aspect-ratio: 1/1;
        }
        &--selected{
            border-color: #0057ff;
        }
    }
    &--greenwhite{
        display: flex;
        gap: 1.4rem;
        min-height: 5rem;
        width: fit-content;
        border: none;
        background: white;
        color: #24d11b;
        font-size: 1.6rem;
        cursor: pointer;
        padding: 0 2rem;
        font-weight: 400;
        box-shadow: 0px 5px 5px 0px rgba(0, 4, 21, 0.03);
        > div{
            height: 2rem;
            aspect-ratio: 1/1;
            fill: #24d11b;
            z-index: 999;
            .st0{
                fill: #24d11b!important;
            }
        }
    }
    &--greywhite{
        height: 4rem;
        padding: 0 1.5rem;
        color: #8a93a1;
        font-size: 1.6rem;
        font-family: inherit;
        background: white;
        box-shadow: 0px 5px 5px 0px rgba(0, 4, 21, 0.03);
        &:hover{
            color: #0057ff;
        }
    }
}

.fa-file-{
    &pdf{
        color: #424c55;
    }
    &powerpoint{
        color: #F5632D;
    }
    &word{
        color: #0057ff;
    }
    &excel{
        color: #017338;
    }
}

.link{
    color: #0057ff;
    background: none;
    text-decoration: underline #0057ff;
    border: none;
    transition: .1s ease-in;

    &:hover{
        margin-top: -.2rem;
    }
}
