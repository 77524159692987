.leftLoginPane{
    width: 28%;
    height: 100vh;
    background: #222e44;
    background-image: url(../../assets/Artwork.webp);
    background-size: cover;
    padding: 8rem;

    @media screen and (max-width: 87.5rem) {
        padding: 4rem;
    }
    @media screen and (max-width: 62.5rem) {
        width: 100%;
        height: 25%;
    }
}

    .textWrapper{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
    }

    .leftLoginPane__textWrapper__img{
        position: absolute;
        max-width: 27rem;
        width: 100%;
        height: fit-content;
        top: 0;
        fill: white!important;
        polygon{
            fill: white!important;
        }
        .st5{
            fill: white!important;
        }
        @media screen and (max-width: 62.5rem) {
            position: initial;
        }
    }

    .textWrapper__div{
        display: flex;
        flex-direction: column;
        gap: 4rem;
        @media screen and (max-width: 62.5rem) {
            display: none;
        }
    }

    .textWrapper__div__h2{
        font-size: 2.5rem;
        font-weight: 400;
    }

    .textWrapper__div__h1{
        font-size: 5rem;
        font-weight: 500;
        @media screen and (max-width: 62.5rem) {
            font-size: 4rem;
        }
    }
    
