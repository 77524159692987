.berichten{
}
.berichten__title{
    padding-bottom: 2rem;
}
.berichten__header{
    border-bottom: 1px solid hsl(212, 21%, 88%);
    padding-bottom: 2rem;
    margin-bottom: 5rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.berichten__header__filterwrapper{
    display: flex;
    gap: 1rem;
}
.berichten__header__archivebutton{
    font-family: "Roboto", sans-serif;
    border: none;
    background: none;
    color: #0057ff;
    cursor: pointer;
    font-size: calc(15px + 3 * (100vw - 400px) / 1620);
    &:hover{
        text-decoration: underline;
    }
}

.berichten__berichtwrapper{
    background: white;
    width: 100%;
    padding: 0 3rem;
    box-shadow: 0px 5px 5px 0px rgba(0, 4, 21, 0.03);
}
.berichten__bericht{
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;
    border-bottom: 1px solid #eceff2;
    background: white;
    width: 100%;
    &:last-child{
        border-bottom: none;
    }
    @media screen and (max-width: 28.125rem) {
        flex-direction: column;
        gap: 2rem;
    }
}

.berichten__bericht__textwrapper {
    display: flex;
    align-items: center;
    gap: 2rem;
    cursor: pointer;
    &:hover{
        .berichten__bericht__textwrapper__div--title{
            text-decoration: underline;
        }
    }
}

.berichten__bericht__textwrapper__div{
    display: flex;
    align-items: center;
    gap: 2rem;

    &--title {
        font-size: 1.6rem;
        color: #69717e;
        min-width: fit-content;
    }
    &--date {
        font-size: 1.2rem;
        color: #7f8a99;
    }
    &--important{
        color: red;
        font-size: 2.2rem;
    }
    @media screen and (max-width: 43.75rem) {
        flex-direction: column;
        gap: 0;
        align-items: start;
    }
}
.berichten__bericht__textwrapper__figure{
    height: 3rem;
    position: relative;
    aspect-ratio: 1/1;
    background: #f2f6ff;
    border-radius: 50%;
    box-shadow: 30px 0 30px -30px rgba(0, 4, 21, 0.05) inset;
    &--img{
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        aspect-ratio: 1/1;
    }
    &:hover &__uploadedby{
        opacity: 1;
        pointer-events: all;
    }
}
.berichten__bericht__textwrapper__figure__uploadedby{
    color: #4b5563;
    transition: 0.4s;
    pointer-events: none;
    opacity: 0;
    z-index: 999;
    background: white;
    border-radius: 3px   ;
    position: absolute;
    font-size: 1.2rem;
    min-width: max-content;
    top: -3px;
    left: 2rem;
    box-shadow: 0 0 0.5rem hsl(0, 0%, 85%);
    padding: 3px;
}
.berichten__bericht__btnwrapper {
    display: flex;
    gap: 1.5rem;
    @media screen and (max-width: 28.125rem){
        padding-left: 1rem;
    }
}
.berichten__bericht__btnwrapper__btn{
    font-size: 2.2rem;
    color: #0057ff;
    min-width: fit-content;
    border: none;
    background: none;
    cursor: pointer;
}

.berichten__footer{
    padding-top: 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
}
.berichten__footer__amountshown{
    font-size: 1.2rem;
    color: #69717e;
}
.berichten__footer__amountprogressbar{
    width: 20rem;
    height: 0.4rem;
    background: white;
    &--progress{
        height: 100%;
        background: #d9dfe6;
    }
}
.berichten__footer {
    .berichten__footer__loadmorebutton {
        border-radius: 3px;
        padding: 1rem 2.5rem;
        background: white;
        color: #0057ff;
        font-size: 1.2rem;
        font-weight: 400;
        transition: 0.3s;
        &:hover{
            margin-top: 0.8rem;
        }
    }
}




.addberichtbutton{
    position: absolute;
    bottom: 5rem;
    right: 5rem;
    font-size: 1.8rem;
    font-family: inherit;
    border: none;
    background: #fff;
    padding: 1.5rem 4rem;
    color: #0057ff;
    border-radius: 8px;
    box-shadow: 0 0 2rem hsl(0, 0%, 95%);
    border: 1px solid #0057ff;
    cursor: pointer;
}
.addberichtpopup{
    position: absolute;
    left: 5rem;
    bottom: 5rem;
    width: 50rem;
    height: 80rem;
    background: #fff;
    z-index: 999;
    box-shadow: 0 0 2rem hsl(0, 0%, 90%);
    padding: 3rem;
    h3{
        font-size: 2.6rem;
        color: #4b5563;
        margin-bottom: 2rem;
        font-weight: 500;
    }
    form{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        div{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            label{

            }
            input{
                height: 3rem;
                &:focus{
                    outline: none;
                }
            }
        }
        button{
            border-radius: 3px;
            padding: 1rem 2.5rem;
            background: #fff;
            box-shadow: 0 0 2rem hsl(0, 0%, 90%);
            color: #0057ff;
            font-size: 1.6rem;
            font-family: inherit;
            font-weight: 400;
            transition: 0.3s;
            border: none;
            cursor: pointer;
            &:hover{
                transform: translateY(-2px);
            }
        }
    }
}
