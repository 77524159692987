.cmsbutton{
    width: 100%;
    max-width: 25rem;
    height: 5rem;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 4px;
    color: #0057ff;
    box-shadow: 0px 5px 5px 0px rgba(0, 4, 21, 0.03);
    cursor: pointer;
}