.projectDetailsTitle{
    padding: 3rem;
    font-size: 2rem;
    border-bottom: 1px solid #eceff2;
    display: flex;
    justify-content: space-between;

    .projectDetailsTitle__title{
        color: #49535d;
        font-weight: 400;
    }
    > i{
        color: #b4c0d3;
        width: 1.4rem;
        cursor: pointer;
    }
    .fa-plus{
        display: none;
    }
    &--isclosed{
        border: none;
        .fa-minus{
            display: none;
        }
        .fa-plus{
            display: block;
        }
    }
}
