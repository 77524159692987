.overview{
    width: 100%;
    height: 100%;
    padding: 10rem;
}
.overview__header{
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    border-bottom: #e6e9ed 1px solid;
}
.overview__header__title{
    font-size: 3rem;
    font-weight: 500;
    color: #424c55;
}
.overview__header__user{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.overview__header__user__name{
    font-size: 1.6rem;
    color: #424c55;
}
.overview__header__user__figure{
    width: 30px;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    background: #e6e9ed;
}
.overview__header__user__figure__img{
    
    box-shadow: inset 0 0 1rem rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overview__projects{
    padding: 3rem 0;
    display: grid;
    gap: 3rem;
    justify-content: flex-start;
    grid-template-columns: repeat(auto-fill, minmax(28rem, 38rem));
}

.overview__projects__project{
    height: 30rem;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}
.overview__projects__project:hover{
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
    .overview__projects__project__info__title{
        text-decoration: underline;
    }
}
.overview__projects__project__banner{
    position: relative;
    height: 75%;
    overflow: hidden;
    background: #e6e9ed;
}
.overview__projects__project__banner__dropdown{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    top: -100%;
    transition: all 0.3s ease-in-out;
}
.overview__projects__project__banner:hover .overview__projects__project__banner__dropdown{
    top: 0;
}
.overview__projects__project__banner__dropdown__link{
    color: white;
    font-size: 1.6rem;
    font-weight: 500;
    text-decoration: none;
    padding: 1rem 2rem;
    border-radius: 5px;
    border: 3px solid #49535d;
    transition: all 0.3s ease-in-out;
    background: none;
    font-size: 1.8rem;
    cursor: pointer;
    font-weight: inherit;
}
.overview__projects__project__banner__figure{

}
.overview__projects__project__banner__figure__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overview__projects__project__info{
    background: white;
    height: 25%;
    padding: 1rem;

}
.overview__projects__project__info__title{
    color: #49535d;
    font-size: 1.8rem;
    font-weight: 500;
}
.overview__projects__project__info__date{
    color: #49535d;
    font-size: 1.4rem;
    font-weight: 400;
}