.goedgekeurdDoor{
    color: #24d11b;
    background: #eefceb;
    border-radius: 3px;
    min-width: 30rem;
    height: 100%;
    min-height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    padding: 0 1rem;
    width: 30rem;
    span{
        padding-right: 1rem;
    }
    @media screen  and (max-width: 43.75rem){
        width: 100%;
    }
    @media screen and (max-width: 31.25rem) {
        margin-bottom: 2rem;
    }
}