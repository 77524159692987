.documenten{

}

.documenten__header{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-bottom: 5rem;
}
.documenten__header__title{
    line-height: 3rem;
}

.documenten__header__form{
    width: 100%;
    position: relative;
    height: 6rem;
}
.documenten__header__form__input{
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background: white;
    border: none;
    font-size: 1.6rem;
    border: 1px solid #d9dfe6;
    flex-grow: 1;
    padding-left: 2rem;
    color: #7f8a99;
    font-family: inherit;
    &:focus{
        outline: none;
    }
}
.documenten__header__form__button{
    position: absolute;
    right: 0;
    height: 100%;
    aspect-ratio: 1/1;
    background: none;
    border: none;
    cursor: pointer;
    &--icon{
        font-size: 2rem;
        color: #8a93a1;
    }
}

.documenten__header__wrapper{
    height: 5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 87.5rem) {
        flex-direction: column;
        gap: 1rem;
        height: fit-content;
    }
    @media screen and (max-width: 56.25rem) {
        align-items: center;
    }
}

.documenten__header__filterwrapper{
    display: flex;
    gap: 1rem;
    @media screen and (max-width: 56.25rem) {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

.documenten__header__filterwrapper{
.documenten__header__filterwrapper__doctypefilter{
    height: 100%;
    min-height: 5rem;
    font-size: 1.6rem;
    border: none;
    background: white;
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
    color: #0057ff;
    box-shadow: 0px 5px 5px 0px rgba(0, 4, 21, 0.03);
    color: #0057ff;

    &--text{
        text-transform: capitalize;
    }
    &--icon{
        fill: #0057ff;
        height: 2rem;
        aspect-ratio: 1/1;

    }
    &--selected{
        background: #0057ff;
        color: white;
        .documenten__header__filterwrapper__doctypefilter--icon{
            fill: white;
        }
    }
    @media screen and (max-width: 56.25rem){
        width: 100%;
    }
}}
.documenten__header__filterwrapper__uploadedbyfilter{
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    gap: 1rem;
    cursor: pointer;
    padding-left: 2rem;

    &--chevron{
        color: #8a93a1;
        font-size: 1.1rem;
        padding: 0.9rem;
        background: white;
        border-radius: 50%;
        transition: 0.2s ease-in;
        box-shadow: 0px 5px 5px 0px rgba(0, 4, 21, 0.03);
    }
}
.documenten__header__filterwrapper__uploadedbyfilter__figurewrapper{
    background: white;
    height: 5rem;
    aspect-ratio: 1/1;
    padding: 2px;
    border-radius: 50%;
    transition: 0.2s ease-in;
    box-shadow: 0px 5px 5px 0px rgba(0, 4, 21, 0.03);
}
.documenten__header__filterwrapper__uploadedbyfilter:hover .documenten__header__filterwrapper__uploadedbyfilter__figurewrapper{
    transform: scale(1.1);
}
.documenten__header__filterwrapper__uploadedbyfilter:hover .documenten__header__filterwrapper__uploadedbyfilter--chevron{
    transform: scale(1.1);
}
.documenten__header__filterwrapper__uploadedbyfilter__figure{
    height: 100%;
    aspect-ratio: 1/1;
    background: #f2f6ff;
    border-radius: 50%;
    box-shadow: 30px 0 30px -30px rgba(0, 4, 21, 0.05) inset;
    background: white;
    &--img{
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        width: 100%;
    }
}

.documenten__header__filterwrapper__userfilterpopup{
    max-width: 40rem;
    height: fit-content;
    max-height: 30rem;
    z-index: 999;
    background: white;
    position: relative;
    box-shadow: 30px 0 30px -30px rgba(0, 4, 21, 0.13);
    border-radius: 3px;
    padding: 2px;
    margin-left: 2rem;
    border-radius: 3px;
    // border-top-left-radius: 15%;
}
.userfilterpopup__ul{
    list-style: none;
    height: 100%;
    max-height: 30rem;
    overflow: auto;
    scrollbar-width: thin;
    padding: 0.4rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.userfilterpopup__ul__li{
    cursor: pointer;
    height: 5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.userfilterpopup__ul__li__figure{
    height: 100%;
    aspect-ratio: 1/1;
    background: #f2f6ff;
    border-radius: 50%;
    box-shadow: 30px 0 30px -30px rgba(0, 4, 21, 0.05) inset;
    background: white;
    &--img{
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        width: 100%;
    }
}
.userfilterpopup__ul__li__name{
    font-weight: 400;
    font-size: 2rem;
    color: #424c55;
    min-width: fit-content;
}

.documenten__header{
.document__header__uploadbutton{
    
}}


.documenten__titlewrapper{
    width: 100%;
    padding-bottom: 2rem;
    padding-top: 4.5rem;
    border-top: 1px solid #d9dfe6;
}
.documenten__titlewrapper__title{
    width: 100%;
    font-size: 2.6rem;
    font-weight: 500;
    color: #424c55;
    height: 2.6rem;
}

.documenten__documentwrapper{
    background: white;
    width: 100%;
    padding: 0 3rem;
    box-shadow: 0px 5px 5px 0px rgba(0, 4, 21, 0.03);
}
.documenten__document{
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    border-bottom: 1px solid #eceff2;
    background: white;
    width: 100%;
    &:last-child{
        border-bottom: none;
    }
    @media screen and (max-width: 37.5rem) {
        flex-direction: column;
        gap: 2rem;
    }
}

.documenten__document__textwrapper {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.documenten__document__textwrapper__div{
    display: flex;
    align-items: center;
    gap: 2rem;

    &--title {
        font-size: 1.6rem;
        color: #69717e;
        min-width: fit-content;
    }
    &--date {
        min-width: fit-content;
        font-size: 1.2rem;
        color: #7f8a99;
    }
    @media screen and (max-width: 43.75rem) {
        flex-direction: column;
        gap: 0;
        align-items: start;
    }
}
.documenten__document__textwrapper__figure{
    height: 3rem;
    position: relative;
    aspect-ratio: 1/1;
    background: #f2f6ff;
    border-radius: 50%;
    box-shadow: 30px 0 30px -30px rgba(0, 4, 21, 0.05) inset;
    &--img{
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
    &:hover &__uploadedby{
        opacity: 1;
        pointer-events: all;
    }
}
.documenten__document__textwrapper__figure__uploadedby{
    color: #4b5563;
    transition: 0.4s;
    pointer-events: none;
    opacity: 0;
    z-index: 999;
    background: white;
    border-radius: 3px   ;
    position: absolute;
    font-size: 1.2rem;
    min-width: max-content;
    top: -3px;
    left: 2rem;
    box-shadow: 0 0 0.5rem hsl(0, 0%, 85%);
    padding: 3px;
}
.documenten__document__linkwrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    @media screen and (max-width: 37.5rem){
        padding-left: 1rem;
    }
}
.documenten__document__linkwrapper__img{
    height: 2rem;
    aspect-ratio: 1/1;
    fill: #0057ff;
    > div{
        height: 2rem;
    }
}
.documenten__document__linkwrapper__link{
    font-size: 1.6rem;
    color: #0057ff;
    min-width: fit-content;
    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }
}

.documenten__footer{
    padding-top: 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
}
.documenten__footer__amountshown{
    font-size: 1.2rem;
    color: #69717e;
}
.documenten__footer__amountprogressbar{
    width: 20rem;
    height: 0.4rem;
    background: white;
    &--progress{
        height: 100%;
        background: #d9dfe6;
    }
}
.documenten__footer {
    .documenten__footer__loadmorebutton {
        border-radius: 3px;
        padding: 1rem 2.5rem;
        background: white;
        color: #0057ff;
        font-size: 1.2rem;
        font-weight: 400;
        transition: 0.3s;
        &:hover{
            margin-top: 0.8rem;
        }
    }
}
