.progresscircle{
    padding: 1.5rem;
    border-radius: 4px;
    // border: 1px solid #e6e9ed;
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0px 5px 5px 0px rgba(0, 4, 21, 0.05);
    width: 100%;
    max-width: 20rem;
    min-width: 18rem;
}
.progresscircle__title{
    color: #424c55;
    
}

.progresscircle__circle {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background: 
    radial-gradient(closest-side, white 79%, transparent 80% 100%),
    conic-gradient(#0057ff var(--percentage), #d6e0f5 0);
    box-shadow: inset 0 0 1rem rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &--overtime{
        background: 
        radial-gradient(closest-side, white 79%, transparent 80% 100%),
        conic-gradient(#ff8400 var(--percentage), #d6e0f5 0);
    }
}
.progresscircle__circle2{
    width: 80%;
    background: 
    radial-gradient(closest-side, white 89%, transparent 90% 100%),
    conic-gradient(#24d11b var(--percentage), #e0fcdc 0);
    box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.25);
}
.progresscircle__circle__title{
    font-size: 2.6rem;
    font-weight: 500;
    color: #424c55;
}
.progresscircle__circle__subtitle{
    color: #8a93a1;
    font-size: 1.6rem;
    font-weight: 500;
}