.berichten__header--admin{
    @media screen and (max-width: 34.375rem){
        flex-direction: column;
        gap: 1rem;
    }
}

.berichten__header__wrapper{
    display: flex;
    gap: 2rem;
    align-items: center;
    @media screen and (max-width: 34.375rem){
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}

.newupdatepopup{
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    background: rgba(0, 0, 0, 0.1);
    z-index: 999;
}
.newupdatepopup__background{
    width: 55vw;
    height: 100vh;
}
.newupdatepopup__wrapper{
    height: 100vh;
    width: 45vw;
    background: white;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
    padding: 5rem;
}
.newupdatepopup__wrapper__title{

}
.newupdatepopup__wrapper__form{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.newupdatepopup__wrapper__form__inputwrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.newupdatepopup__wrapper__form__inputwrapper__label{
    font-size: 1.4rem;
    font-weight: 600;
}
.newupdatepopup__wrapper__form__inputwrapper__input{
    padding: 1rem;
    border: 1px solid #000;
    border-radius: 5px;
    font-size: 1.4rem;
}
.newupdatepopup__wrapper__form__inputwrapper__textarea{
    padding: 1rem;
    border: 1px solid #000;
    border-radius: 5px;
    font-size: 1.4rem;
    resize: none;
}