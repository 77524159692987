.projectDetails__detail__info{
    &--meerwerk{
        h2{
            font-size: 2rem;
            color: #69717e;
            font-weight: 400;
            padding: 1.5rem 0 2rem 0;
        }

        .meerwerk__designwrapper{
            display: grid;
            gap: 1rem;
            
            grid-template-rows: 4rem 4rem 4rem;
            padding-bottom: 3rem;
            border-bottom: 1px solid #eceff2;
            margin-bottom: 1.5rem;
        }
        .meerwerk__techniekwrapper{
            display: grid;
            gap: 1rem;
            grid-template-rows: 4rem;
        }
    }
}