.uploadfilepopup{
    position: absolute;
    box-shadow: 0 0 2rem hsl(0, 0%, 90%);
    border-radius: 0.5rem;
    background: white;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.uploadfilepopup__title{
    font-size: 2.6rem;
    color: #424c55;
    font-weight: 400;
}
.uploadfilepopup__form{

    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.uploadfilepopup__form__inputwrapper__input{

}
.uploadfilepopup__form__inputwrapper__success{
    color: #24d11b;
    font-weight: 400;

}
.uploadfilepopup__form__inputwrapper__error{
    color: red;
    font-size: 1.4rem;
    font-weight: 400;
}
.uploadfilepopup__form__button{

}