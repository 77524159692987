.projectCoordinatie{
    padding: 3rem;
    border-top: #eceff2 1px solid;

    .projectCoordinatie__header{
        display: flex;
        gap: 1rem;
        h3{
            font-size: 2rem;
            font-weight: 400;
            color: #49535d;
            padding-bottom: 2.5rem;
        }
        p{
            color: #24d11b;
            font-weight: 300;
            b{
                font-weight: 500;
            }
        }
    }

    .projectCoordinatie__progressBar{
        width: 100%;
        border-radius: 3px;
        height: 5rem;
        display: flex;

        &--progress{
            height: 100%;
            font-size: 1.6rem;
            padding: 0 2rem;
            background: #eefceb;
            border: 1px solid #24d11b;
            color: #24d11b;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-width: fit-content;
            b{
                font-weight: 500;
            }
        }
        &--progress-left{
            height: 100%;
            border: 1px solid #d9dfe6;
            border-left: none;
        }
    }
}