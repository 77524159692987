$main-title-color: #424c55;
$main-title-font-size: 3rem;
$main-title-font-weight: 500;


.main-title{
    width: 100%;
    font-size: $main-title-font-size;
    font-weight: $main-title-font-weight;
    color: $main-title-color;
}