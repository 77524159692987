.createissuepopup{
    z-index: 900;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    background: rgba(0,0,0,0.1);
}
.createissuepopup__left{
    width: 55vw;
    height: 100vh;
    cursor: pointer;
    @media screen and (max-width: 75rem) {
        width: 35vw;
    }
    @media screen and (max-width: 36.5rem) {
        width: 0;
    }
}
.createissuepopup__right{
    height: 100vh;
    width: 45vw;
    background: #f5f7f9;
    box-shadow: 0 0 2rem rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 75rem) {
        width: 65vw;
    }
    @media screen and (max-width: 36.5rem) {
        width: 100vw;
    }
}

.createissuepopup__right__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5rem;
}
.createissuepopup__right__header__title{
    font-size: 3rem;
    font-weight: 500;
    color: #424c55;
}
.createissuepopup__right__header__close{
    color: #424c55;
    font-size: 2.2rem;
    cursor: pointer;
}

.createissuepopup__right__form{
    padding: 5rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.createissuepopup__right__form__inputwrapper{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.createissuepopup__right__form__inputwrapper__label{

}
.createissuepopup__right__form__inputwrapper__input{
    background: #fff;
    border: 1px solid #d9dfe6;
    border-radius: 3px;
    font-size: 2rem;
    height: 6rem;
    padding-left: 2rem;
    width: 60%;
    &:focus{
        outline: none;
    }
}
.createissuepopup__right__form__textareawrapper{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.createissuepopup__right__form__textareawrapper__label{

}
.createissuepopup__right__form__textareawrapper__textarea{
    background: #fff;
    border: 1px solid #d9dfe6;
    font-family: inherit;
    border-radius: 3px;
    font-size: 1.6rem;
    padding: 1rem;
    height: 12rem;
    width: 100%;
    resize: none;
    &:focus{
        outline: none;
    }
}
.createissuepopup__right__form__button{
    background: #0057ff;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    font-size: 2rem;
    height: 6rem;
    width: 60%;
}