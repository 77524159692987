.forgotPWPopup{
    width: 100%;
    height: 100vh;
    background: rgba(66, 76, 85,40%);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.forgotPWPopup__form{
    width: 55rem;
    padding-bottom: 5rem;
    background: white;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
}

.forgotPWHeader{
    height: 8rem;
    width: 100%;
    border-bottom: 1px solid #d9dfe6;
    padding: 3rem;
}
.forgotPWHeader__title{
    color: black;
    font-weight: 400;
    font-size: 2rem;
}


.forgotPWPopup__form__inputWrapper{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.forgotPWPopup__form__inputWrapper__label{
    color: #49535d;
}

.forgotPWPopup__form__inputWrapper__input{
    border-radius: 3px;
    background: white;
    width: 50rem;
    height: 6rem;
    border: none;
    font-size: 1.6rem;
    border: 1px solid #d9dfe6;
}

.forgotPWPopup__form__buttonWrapper{
    width: min-content;
}

.forgotPWPopup__form__buttonWrapper__resetButton{
    background: #24d11b;
    color: white;
    border: none;
    border-radius: 3px;
    width: 50rem;
    height: 6rem;
    font-size: 2rem;
    cursor: pointer;
}

.forgotPWPopup__form__buttonWrapper__backButton{
    background: none;
    color: #49535d;
    font-weight: 500;
    border: none;
    border-radius: 3px;
    width: 50rem;
    height: 6rem;
    font-size: 2rem;
    cursor: pointer;
}


.forgotPWPopup__errors{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 1rem;
    width: fit-content;
    width: 100%;
    border-radius: 1rem;
    .forgotPWPopup__error__p{
        display: flex;
        justify-content: center;
        color: red;
    }
}

.forgotPWPopup__success{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    background: #24d11b;
    width: fit-content;
    border-radius: 1rem;
    .forgotPWPopup__success__p{
        color: white;
    }
}