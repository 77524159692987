.projectoverzicht{
    width: 100%;
    height: 100%;
    padding: 10rem;
}
.projectoverzicht__header{
    width: 100%;
    display: flex;
    gap: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid #d9dfe6;
}
.projectoverzicht__header__title{
    display: flex;
    align-items: center;
    line-height: 3rem;
}
.projectoverzicht__header__filterwrapper{
    display: flex;
    gap: 1rem;
    @media screen and (max-width: 56.25rem) {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}
.projectoverzicht__header__filterwrapper__filter{
    height: 100%;
    min-height: 5rem;
    font-size: 1.6rem;
    border: none;
    background: white;
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 0px 5px 5px 0px rgba(0, 4, 21, 0.03);
    color: #0057ff;
    .st0{
        fill: #0057ff;
    }
    &--selected{
        background: #0057ff;
        color: white;
        .projectoverzicht__header__filterwrapper__filter__icon{
            fill: white;
        }
        .st0{
            fill: white;
        }
    }
    @media screen and (max-width: 56.25rem){
        width: 100%;
    }
}   
.projectoverzicht__header__filterwrapper__filter__icon{
    fill: #0057ff;
    height: 2rem;
    aspect-ratio: 1/1;
    svg{
        width: 100%;
        height: 100%;
    }
}
.projectoverzicht__header__filterwrapper__filter__text{
    text-transform: capitalize;
}

.projectoverzicht__projecten{
    padding: 3rem;
    width: 100%;
    display: grid;
    gap: 3rem;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(35rem, 40rem));
}
.projectoverzicht__projecten__project{
    position: relative;
    text-decoration: none;
    color: initial;
    width: 100%;
    &:hover{
        .projectoverzicht__projecten__project__figure{
            transform: scale(1.03) translateY(-1px);
        }
        .projectoverzicht__projecten__project__content{
            box-shadow: 0 0 2rem rgba(0, 0, 0, 0.25);
        }
    }
}
.projectoverzicht__projecten__project__figurewrapper{
    height: 5rem;
}
.projectoverzicht__projecten__project__figure{
    margin: 0 2rem;
    transition: 0.2s ease;
    background: lightgray;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    height: 100%;
    cursor: pointer;
}
.projectoverzicht__projecten__project__figure__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
}
.projectoverzicht__projecten__project__content{
    background: white;
    transition: 0.2s ease;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 1rem;
    cursor: pointer;
}
.projectoverzicht__projecten__project__content__header{
   
}
.projectoverzicht__projecten__project__content__header__title{
    font-weight: 500;
    color: #424c55;
    font-size: 2.4rem;
}
.projectoverzicht__projecten__project__content__header__date{
    font-size: 1.4rem;
    color: #677079;
    margin-bottom: 0.5rem;
}
.projectoverzicht__projecten__project__content__ul{
    list-style: none;
    padding: 1rem 0;
}
.projectoverzicht__projecten__project__content__ul__li{
    font-size: 1.8rem;
    color: #424c55;
    span{
        font-size: 2rem;
        color: #0057ff;
    }
}
.projectoverzicht__projecten__project__content__progressbar{
    background: #d6e0f5 ;
    box-shadow: inset 0 0 1rem rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    overflow: hidden;
    height: 2rem;
    &--inner{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 0.5rem;
        background: #0057ff;
        min-width: fit-content;
        color: #d9dfe6;
        font-size: 1.3rem;
    }
}